
.bg-section-contact{
    background-image: url(/public/assets/image/bg-image-contact-page.jpg);
    background-color: #000;
    position: relative;
}
.bg-section-contact::after{
    content: '';
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(128, 9, 9, 0.9);
}
.bg-section-contact .container-fluid
{
    position: relative;
    z-index: 99;
}

 /* contact form style  */
.contact-info {
    padding: 50px;
    color: #fff; 
}
.contact-info .title{
    font-size: 35px;
    text-align: center;
}

.contact-info .contact-card{
    margin: 4em 0;
}
.contact-info .contact-card .heading{
    display: flex;
    align-content: center;
    margin-bottom: 1em;
    color: #fff;
}
.contact-info .contact-card .heading i{
    margin-right: 1em;
    font-size: 1.5em;
}
.contact-info .contact-card .nav-link{
    color: #000;
    padding: 5px;
    color: #fff;
}
.contact-info .contact-card .link-list{
    display: flex;
    flex-wrap: wrap;
}
 .contact-form{
    width: 100%;
    background: rgba(255, 255, 255, 0.31);
    padding: 30px; 
    margin: 2rem 0;
}
.contact-form .form-label{
    font-size: 18px;
    color: #fff;
}
.contact-form .eva-btn{
    text-transform: uppercase;
}


@media screen and (max-width:983px) {
    .bg-section-contact .container .row.response-reverse {
        flex-direction: column;
    }
    .bg-section-contact .container .row{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:767px) {
    .contact-info{
        padding: 10px;
    }    
}

@media screen and (max-width:410px) {
    .contact-form{
        padding: 25px 15px;
    }
}